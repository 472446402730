<template>
  <div class="comboProduct">
    <div
      style="display: flex; align-items: center; justify-content: space-between"
    >
      <h2>体检项目</h2>
      <a-button style="width: 85px" type="primary" @click="goBack"
        >返回
      </a-button>
    </div>

    <div class="button-box">
      <a-button style="width: 115px" type="primary" @click="showConfirm"
        >获取体检项目
      </a-button>
      <a-button
        :disabled="disabled"
        style="width: 85px"
        type="primary"
        @click="sendData"
        >保存
      </a-button>
    </div>
    <div style="margin-top: 5px; margin-bottom: 5px">PS:双击单元格即可编辑</div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :pagination="false"
      bordered
    >
      <template #bodyCell="{ column, text, index }">
        <template v-if="column.dataIndex === 'name'">
          <div class="editable-cell">
            <div
              v-if="`${index}name` === selectInput"
              class="editable-cell-input-wrapper"
            >
              <a-input
                v-model:value="editableData[0].name"
                @pressEnter="save(index)"
                @blur="save(index)"
              />
            </div>
            <div
              v-else
              class="editable-cell-text-wrapper"
              @dblclick="edit(index, `${index}name`)"
            >
              {{ text || ' ' }}
            </div>
          </div>
        </template>
        <template v-if="column.dataIndex === 'type'">
          <div class="editable-cell">
            <div
              v-if="`${index}type` === selectInput"
              class="editable-cell-input-wrapper"
            >
              <a-input
                v-model:value="editableData[0].type"
                @pressEnter="save(index)"
                @blur="save(index)"
              />
            </div>
            <div
              v-else
              class="editable-cell-text-wrapper"
              @dblclick="edit(index, `${index}type`)"
            >
              {{ text || ' ' }}
            </div>
          </div>
        </template>
        <template v-if="column.dataIndex === 'checkRange'">
          <div class="editable-cell">
            <div
              v-if="`${index}checkRange` === selectInput"
              class="editable-cell-input-wrapper"
            >
              <a-input
                v-model:value="editableData[0].checkRange"
                @pressEnter="save(index)"
                @blur="save(index)"
              />
            </div>
            <div
              v-else
              class="editable-cell-text-wrapper"
              @dblclick="edit(index, `${index}checkRange`)"
            >
              {{ text || ' ' }}
            </div>
          </div>
        </template>
        <template v-if="column.dataIndex === 'action'">
          <a-button type="link" danger @click="handleDel(index)">删除</a-button>
          <a-button type="link" @click="handleAdd(index)">插入</a-button>
        </template>
      </template>
    </a-table>
  </div>
</template>
<script setup>
import { computed, createVNode, nextTick, reactive, ref } from 'vue'
import { cloneDeep } from 'lodash-es'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { message, Modal } from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'
import { productDetailEdit, productDetailInit, productDetailList } from '@/api'

const route = useRoute()
const guid = route.query.guid
const getList = () => {
  productDetailList(guid)
    .then((res) => {
      dataSource.value = res.data
    })
    .catch((error) => {
      Modal.error({
        content: error.response.data
      })
    })
}
getList()
//表格信息
const columns = [
  {
    title: '项目种类',
    dataIndex: 'name',
    key: 'name',
    width: '15%'
  },
  {
    title: '检查项目',
    dataIndex: 'type',
    key: 'type',
    width: '20%'
  },
  {
    title: '检查意义',
    dataIndex: 'checkRange',
    key: 'checkRange'
  },
  {
    title: '操作',
    dataIndex: 'action',
    key: 'action',
    width: '200px'
  }
]
let dataSource = ref([])
const editableData = reactive({})
const selectInput = ref('')
//编辑
const edit = (dataIndex, type) => {
  isEdit.value = true
  editableData[0] = cloneDeep(dataSource.value[dataIndex])
  selectInput.value = type
  getInput()
}
//双击编辑
const getInput = async () => {
  await nextTick(() => {
    document.querySelector('.ant-input').focus()
  })
}
//编辑后保存
const save = (index) => {
  selectInput.value = ''
  Object.assign(dataSource.value[index], editableData[0])
  delete editableData[0]
}
//删除
const handleDel = (index) => {
  isEdit.value = true
  dataSource.value.splice(index, 1)
}
//添加
const handleAdd = (index) => {
  isEdit.value = true
  const newData = {
    type: '',
    name: '',
    checkRange: '',
    contetnSex: ''
  }
  dataSource.value.splice(index, 0, newData)
}

//获取体检项目
const getNewList = () => {
  productDetailInit(guid)
    .then((res) => {
      dataSource.value = res.data
    })
    .catch((error) => {
      Modal.error({
        content: error.response.data
      })
    })
}
const showConfirm = () => {
  Modal.confirm({
    title: '是否要获取体检项目?',
    icon: createVNode(ExclamationCircleOutlined),
    centered: true,
    content: createVNode(
      'div',
      {
        style: 'color:red;'
      },
      '获取后将会覆盖数据'
    ),

    onOk() {
      getNewList()
    },

    onCancel() {}
  })
}

//返回
const router = useRouter()
const isEdit = ref(false)
const goBack = () => {
  if (isEdit.value) {
    Modal.confirm({
      title: '是否放弃更改',
      icon: createVNode(ExclamationCircleOutlined),
      content: '您有新的更改未保存是否放弃更改',
      centered: true,
      okText: '放弃',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        router.go(-1)
      },

      onCancel() {}
    })
  } else {
    router.go(-1)
  }
}
const disabled = computed(() => !isEdit.value)
const sendData = () => {
  isEdit.value = false
  Modal.confirm({
    title: '是否保存',
    icon: createVNode(ExclamationCircleOutlined),
    centered: true,
    okText: '保存',
    cancelText: '取消',
    onOk() {
      productDetailEdit(guid, dataSource.value)
        .then(() => {
          message.success('保存成功')
          location.reload()
        })
        .catch((error) => {
          Modal.error({
            content: error.response.data
          })
        })
    },

    onCancel() {}
  })
}
</script>
<style scoped>
.button-box {
  width: 240px;
  display: flex;
  justify-content: space-between;
}

:deep(.ant-table-tbody) .ant-table-cell {
  padding: 2px 16px !important;
}

.editable-cell-text-wrapper {
  width: 100%;
  min-height: 22px;
  word-wrap: break-word;
}
</style>
